/* eslint-disable no-console */
import { cloneElement, CSSProperties, FC, ReactNode, useRef } from 'react';
import Debug from 'debug';

import { getAppHasHydrated } from '../NextApp';

const debug = Debug('songwhip/HydrateScope');
export interface HydrateScopeProps {
  hydrate: boolean;
  render: () => JSX.Element | ReactNode;
  style?: CSSProperties;
}

const IS_SERVER = !process.browser;
const SKIP = {};

const HydrateScope: FC<HydrateScopeProps> = ({ hydrate, render, style }) => {
  const hasHydratedRef = useRef(false);

  // If the app hasn't hydrated yet and this component is being rendered
  // then we know that it was rendered on the server.
  const wasServerRendered = useRef(!getAppHasHydrated()).current;

  const shouldHydrate =
    // always deep-render on server
    IS_SERVER ||
    // if this component instance has deep-rendered before it must continue to do so
    hasHydratedRef.current ||
    // if the hydrate prop is true we always deep render
    hydrate ||
    // If the `hydrate` prop is false but we KNOW this component wasn't
    // server-rendered then we MUST render it. When components are INITIALLY
    // rendered on the client, the full 'deep' render must happen else
    // the component will be empty.
    !wasServerRendered;

  // child must be plain html element otherwise doesn't work
  const child = <div style={style}>{render()}</div>;

  // once hydrated all subsequence renders must hydrate
  if (shouldHydrate) {
    if (!hasHydratedRef.current) {
      hasHydratedRef.current = true;
      debug('hydrate');
    }

    // console.log('hydrate');
    return child;
  }

  // console.log('skip hydrate');

  // @ts-ignore
  return cloneElement(child, {
    dangerouslySetInnerHTML: SKIP,
    children: null,
  });
};

export default HydrateScope;
