import {
  OrchardBrands,
  ReleaseTaskTypes,
  AccountConfig,
  SongwhipUser,
} from '../types';

export interface AccountAnalyticsItemData {
  pageViews?: {
    total: number;

    countries: {
      [country: string]: number;
    };

    referrers: {
      [referrer: string]: number;
    };
  };

  openEvents?: {
    [url: string]: number;
  };

  presaveEvents?: Record<ReleaseTaskTypes, number>;
}

export type AccountAnalytics = {
  timestamp: number;
  date: string;

  artists: {
    [artistId: string]: AccountAnalyticsItemData;
  };

  albums: {
    [albumId: string]: AccountAnalyticsItemData;
  };

  tracks: {
    [trackId: string]: AccountAnalyticsItemData;
  };

  customPages: {
    [customPageId: string]: AccountAnalyticsItemData;
  };
}[];

export interface AccountUser
  extends Omit<SongwhipUser, 'featureFlags' | 'roles' | 'orchardBrand'> {
  isAccountAdmin: boolean;
  joinedAtTimestamp: number;
}

export enum CustomDomainStatuses {
  PENDING = 'pending',
  ACTIVE = 'active',
}

export interface CustomDomain {
  id: number;
  domain: string;
  status: CustomDomainStatuses;
}

export interface MappedAccount {
  id: number;
  name: string;
  users?: AccountUser[];
  orchardBrand?: OrchardBrands;
  config: AccountConfig | null;
  customDomains?: CustomDomain[];
}
