/**
 * A unwrapped promise that allows you to resolve/reject
 * from outside the Promise closure.
 */
const deferred = <TValue = void>() => {
  let resolve: (value: TValue) => void;
  let reject: (value: TValue) => void;

  const promise = new Promise<TValue>((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });

  return {
    promise,
    // @ts-ignore
    resolve,
    // @ts-ignore
    reject,
  };
};

export default deferred;
