import base from './base';
import { Theme } from './types';

const white = (alpha = 1) => `rgba(255,255,255,${alpha})`;
const black = (alpha = 1) => `rgba(0,0,0,${alpha})`;

export const white100 = white(1);
export const white60 = white(0.6);
export const white30 = white(0.3);
export const white40 = white(0.4);
export const white20 = white(0.2);
export const white10 = white(0.1);

const darkTheme: Theme = {
  ...base,
  background: black(),
  backgroundInverted: 'hsl(255,0%,95%)',

  textColor: white100,
  textColor90: 'hsl(255,0%,90%)',
  textColor80: 'hsl(255,0%,80%)',
  textColor70: 'hsl(255,0%,70%)',
  textColor60: 'hsl(255,0%,60%)',
  textColor50: 'hsl(255,0%,50%)',
  textColor40: 'hsl(255,0%,40%)',
  textColor30: 'hsl(255,0%,30%)',
  textColor20: 'hsl(255,0%,20%)',
  textColor10: 'hsl(255,0%,10%)',
  textColorInverted: black(),

  borderColor: white20,
  textInputBackground: '#111',
  textInputBorderColor: 'hsl(255,0%,25%)',

  // EXPERIMENT: why export all these color variants when we
  // can just expose a dynamic function from the theme?
  // UPDATE: As the alpha value is defined in context, the same alpha value
  // might not be appropriate in a light theme VS. dark. This is the job
  // of the theme author to use the right shades in the right contexts.
  toBackground: black,
  toTextColor: white,
};

export default darkTheme;
