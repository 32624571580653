import dynamic from 'next/dynamic';

import { ModalProps } from '~/app/components/Modal';

interface LazyModalProps extends Pick<ModalProps, 'onClose'> {
  /**
   * Triggers import
   */
  isOpen: boolean;
}

export default <Props,>(loader) => {
  const LazyComponent = dynamic(() => loader(), { ssr: false });

  const LazyModal: React.FC<Props & LazyModalProps> = ({
    isOpen,
    onClose,
    ...props
  }) => (isOpen ? <LazyComponent {...props} onClose={onClose} /> : null);

  return LazyModal;
};
