import Debug from 'debug';

import { ItemTypes } from '~/types';

import { AppReduxAction, StatusTypes, StoredError } from '../types';
import { DELETE_ITEM_SUCCESS } from '../lib/deleteItemSuccess';
import mergeItemIntoState from '../lib/mergeItemIntoState';
import { AlbumsActionTypes, StoredAlbum } from './types';
import { MappedAlbum } from '../../songwhipApi/mapper';
import { AccountsActionTypes } from '../accounts/types';

const debug = Debug('songwhip/store/albums/reducer');

type AlbumsStateItem = {
  value: StoredAlbum;
  status: StatusTypes;
  error?: StoredError;
};

export type AlbumsState = {
  [albumId: number]: AlbumsStateItem | undefined;
};

const initialState: AlbumsState = {};

const reducer = (state: AlbumsState = initialState, action: AppReduxAction) => {
  switch (action.type) {
    case AlbumsActionTypes.FETCH_ALBUM_START: {
      debug('on fetch album start: %s', action);
      return mergeItemIntoState(state, action.albumId, {
        status: StatusTypes.PENDING,
      });
    }

    case AlbumsActionTypes.FETCH_ALBUM_SUCCESS: {
      debug('on fetch album success', action);
      const { payload } = action;

      return mergeItemIntoState(state, payload.id, {
        status: StatusTypes.RESOLVED,
        value: toStoredAlbum(payload),
        error: undefined,
      });
    }

    case AlbumsActionTypes.FETCH_ALBUM_ERROR: {
      debug('on fetch album error: %s', action);
      const { albumId, error } = action;

      return mergeItemIntoState(state, albumId, {
        status: StatusTypes.REJECTED,
        error,
      });
    }

    case DELETE_ITEM_SUCCESS: {
      debug('on delete item success', action);
      const { payload } = action;

      if (payload.type !== ItemTypes.ALBUM) {
        return state;
      }

      const nextState = { ...state };
      delete nextState[payload.id];

      return nextState;
    }

    // Purge all items when account config changes to ensure we never render
    // old `ownedByAccounts[].config`. This means that the item will be
    // re-fetched fresh from the server if/when CSR'd. This is a bit lazy
    // as we could selectively invalidate the items that have matching
    // ownedByAccounts[] but that would be more code to ship to every user :p
    case AccountsActionTypes.CONFIG_CHANGE: {
      return {};
    }

    default:
      return state;
  }
};

const toStoredAlbum = (payload: MappedAlbum): StoredAlbum => {
  const { artists, ...rest } = payload;

  return {
    ...rest,
    artistIds: artists?.map(({ id }) => id),
  };
};

export default reducer;
