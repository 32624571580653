import { useSelector } from '~/app/lib/store/redux';
import useLazyModule from '~/app/lib/hooks/useLazyModule';

import {
  selectSessionCustomDomain,
  selectSessionScope,
} from '~/app/lib/store/session/selectors';

const EXTRA_DELAY = 1000;

const useServiceWorker = ({ href }) => {
  const appIsScoped = !!useSelector(selectSessionScope);
  const isCustomDomain = !!useSelector(selectSessionCustomDomain);

  useLazyModule(
    () =>
      import('./register').then((register) => {
        register.default({ href });
      }),
    {
      delay: EXTRA_DELAY,

      // Don't install the service-worker when the app is
      // 'scoped' under a custom domain as it adds all sorts
      // of cross-domain issues and benefits are negligible.
      // We may choose to review this at a later date.
      skip: appIsScoped || isCustomDomain,
    }
  );
};

export default useServiceWorker;
