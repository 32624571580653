import { ItemTypes } from '~/types';
import ApiError from '../../errors/ApiError';
import { MappedTrack } from '../../songwhipApi/mapper';
import { OrchardBrands } from '../../songwhipApi/types';
import { LinksLocalized } from '../../utils/resolveLinksForCountry/lib/localizeLinks';
import { SelectedPrimaryOwnerAccount } from '../lib/selectors';

export enum TracksActionTypes {
  FETCH_TRACK_START = 'FETCH_TRACK_START',
  FETCH_TRACK_SUCCESS = 'FETCH_TRACK_SUCCESS',
  FETCH_TRACK_ERROR = 'FETCH_TRACK_ERROR',
}
export interface FetchTrackStartAction {
  type: TracksActionTypes.FETCH_TRACK_START;
  trackId: number;
}

export interface FetchTrackSuccessAction {
  type: TracksActionTypes.FETCH_TRACK_SUCCESS;
  payload: MappedTrack;
}

export interface FetchTrackErrorAction {
  type: TracksActionTypes.FETCH_TRACK_ERROR;
  trackId: number;
  error: Error;
}

export type TrackAction =
  | FetchTrackStartAction
  | FetchTrackSuccessAction
  | FetchTrackErrorAction;

export interface SelectedTrack {
  type: ItemTypes.TRACK;
  id: number;
  artistId: number;
  artistIds: number[];
  artistName: string;
  artistPagePath: string;
  error: ApiError | Error | undefined;

  /**
   * The resolved image. Favours custom image, falling back to originalImage.
   */
  image: string | undefined;

  /**
   * The original image found by songwhip-lookup
   */
  originalImage: string | undefined;

  isLoading: boolean;
  isOwned: boolean;
  pageBrand?: OrchardBrands;
  isStale: boolean;
  isShallow: boolean;
  hasLinksForUserCountry: boolean;
  links: LinksLocalized | undefined;
  name: string;
  pagePath: string;
  userCanEdit: boolean | undefined;
  ownedByAccountIds: number[] | undefined;
  config: MappedTrack['config'];
  primaryOwnerAccount: SelectedPrimaryOwnerAccount | undefined;
  customLinks: MappedTrack['customLinks'];
}

export interface StoredTrack extends Omit<MappedTrack, 'artists'> {
  artistIds: number[];
}
