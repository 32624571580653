const onceDocumentLoaded = () =>
  new Promise<void>((resolve) => {
    if (document.readyState === 'complete') return resolve();

    addEventListener('load', function fn() {
      removeEventListener('load', fn);
      resolve();
    });
  });

export default onceDocumentLoaded;
