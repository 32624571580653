export enum AccountsActionTypes {
  CONFIG_CHANGE = 'ACCOUNT_CONFIG_CHANGE',
}

export interface AccountConfigChange {
  type: AccountsActionTypes.CONFIG_CHANGE;
  accountId: number;
}

export type AccountAction = AccountConfigChange;
