import { OrchardBrands } from '../songwhipApi/types';
import { getClientCookie } from '../utils/cookie';
import { UserTypes } from '~/types';

import {
  USER_BRAND_COOKIE_NAME,
  USER_ID_COOKIE_NAME,
  USER_TYPE_COOKIE_NAME,
} from './constants';

export const getUserClientCookieData = () => {
  const userId = getClientCookie(USER_ID_COOKIE_NAME);

  if (userId) {
    return {
      userId: Number(userId),
      userType: getClientCookie<UserTypes>(USER_TYPE_COOKIE_NAME),
      userBrand: getClientCookie<OrchardBrands>(USER_BRAND_COOKIE_NAME),
    };
  }
};
