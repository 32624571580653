const BASE = 6;

export const values = {
  'x-small': BASE / 2,
  xs: BASE / 2,
  xSmall: BASE / 2,

  small: BASE,
  s: BASE,

  medium: BASE * 2,
  m: BASE * 2,

  large: BASE * 3,
  l: BASE * 3,

  'x-large': BASE * 4,
  xLarge: BASE * 4,
  xl: BASE * 4,

  xxl: BASE * 6,
  xxxl: BASE * 8,
};

export const toPixels = (key) => {
  if (/(em|%|vh|vw|auto)$/.test(key)) return key;

  const asNumber = parseInt(key, 10);
  if (asNumber) return asNumber;

  const isNegative = key[0] === '-';

  // remove negative prefix
  if (isNegative) key = key.slice(1);

  const value = values[key];

  // key not found
  if (!value) return 0;

  return isNegative ? -value : value;
};
