import { useEffect } from 'react';

import { onceAppHydrated } from '~/app/lib/utils/onceAppHydrated';
import useIsUnmounted from '../useIsUnmounted';
import wait from '~/app/lib/utils/wait';

/**
 * Load a non-critical module 'lazily' after app-hydrated.
 * This splits the code out of the main bundle and defers
 * execution to avoid blocking critical startup path.
 */
const useLazyModule = (
  loader: () => Promise<any>,
  { delay = 0, skip }: { delay?: number; skip?: boolean } = {}
) => {
  const isUnmounted = useIsUnmounted();

  useEffect(() => {
    if (!skip) {
      onceAppHydrated(async () => {
        await wait(delay);

        if (isUnmounted()) return;

        try {
          const exports = await loader();

          // if exports returned attempt to exec the default
          if (exports?.default) exports.default();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('error loading lazy module', error);
        }
      });
    }
  }, []);
};

export default useLazyModule;
