import Debug from 'debug';

import { ArtistsActionTypes as ActionTypes, StoredArtist } from './types';
import { AppReduxAction, StatusTypes, StoredError } from '../types';
import mergeItemIntoState from '../lib/mergeItemIntoState';
import { AccountsActionTypes } from '../accounts/types';

const debug = Debug('songwhip/store/artists/reducer');

export type ArtistsStateItem = {
  value?: StoredArtist;
  status: StatusTypes;
  error?: StoredError;

  /**
   * Indicates if the stored Artist may not be a 'full' enriched Artist
   * Record. Partial Artists derive from within Albums/Tracks, they are
   * fine for most cases but may be missing things like `artist.albums[]`
   * which is required to render Artist pages.
   */
  isPartial?: boolean;
};

export type ArtistsState = {
  [artistId: string]: ArtistsStateItem | undefined;
};

const initialState: ArtistsState = {};

const reducer = (
  state: ArtistsState = initialState,
  action: AppReduxAction
) => {
  switch (action.type) {
    case ActionTypes.FETCH_START: {
      debug('on fetch artist start');

      return mergeItemIntoState(state, action.artistId, {
        status: StatusTypes.PENDING,
      });
    }

    /**
     * Called when a request to resolve a path to an item succeeds.
     *
     * When this item is an Artist then we store it so that it can
     * be looked up by id. When it's an Album or Track we suck in
     * the nested Artists so that they are stored centrally avoiding
     * any risk of duplicate records in the store.
     */
    case ActionTypes.FETCH_SUCCESS: {
      debug('on fetch artist success', action);

      const { payload, isPartial } = action;
      const existingArtist = state[payload.id];

      // don't overwrite any existing artists with partial/nested artist records
      if (existingArtist && isPartial) {
        debug('artist already in store: skipped merging', {
          existingArtist,
          artist: payload,
        });

        return state;
      }

      const nextState = mergeItemIntoState(state, payload.id, {
        status: StatusTypes.RESOLVED,
        value: payload,
        isPartial,
        error: undefined,
      });

      return nextState;
    }

    case ActionTypes.FETCH_ERROR: {
      debug('on fetch artist error', action);
      const { artistId, error } = action;

      return mergeItemIntoState(state, artistId, {
        status: StatusTypes.REJECTED,
        error,
      });
    }

    // Purge all items when account config changes to ensure we never render
    // old `ownedByAccounts[].config`. This means that the item will be
    // re-fetched fresh from the server if/when CSR'd. This is a bit lazy
    // as we could selectively invalidate the items that have matching
    // ownedByAccounts[] but that would be more code to ship to every user :p
    case AccountsActionTypes.CONFIG_CHANGE: {
      return {};
    }

    default:
      return state;
  }
};

export default reducer;
