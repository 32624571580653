/**
 * Get the official public endpoint of this distribution.
 *
 * This will still return `songwhip.com` when app if proxied via a Custom Domain.
 */
const getPublicEndpoint = () => process.env.PUBLIC_ENDPOINT!;

export const toPublicEndpoint = (path: string) =>
  new URL(path, getPublicEndpoint()).toString();

export default getPublicEndpoint;
