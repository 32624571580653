import { MappedUser } from '~/app/lib/songwhipApi/users/types';
import { SessionActionTypes, SessionInitClientAction } from '../types';

export type SessionInitClientParams = Omit<SessionInitClientAction, 'type'>;

export const sessionInitClient = (
  props: SessionInitClientParams
): SessionInitClientAction => ({
  type: SessionActionTypes.SESSION_INIT_CLIENT,
  ...props,
});

export const setUserType = (value) => {
  return {
    type: SessionActionTypes.SET_USER_TYPE,
    value,
  };
};

/**
 * Action Creators
 */

export const fetchUserSuccess = (user: MappedUser) => ({
  type: SessionActionTypes.FETCH_USER_SUCCESS,
  user,
});
