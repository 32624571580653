export const forEachValue = <T>(
  obj: T | undefined,
  fn: (item: T[keyof T], key: string, index: number) => void
) => {
  obj && Object.keys(obj).forEach((key, index) => fn(obj[key], key, index));
};

export const findValue = <T extends Record<string, unknown>>(
  obj: T | undefined,
  fn: (item: T[keyof T], key?: string) => any
) => {
  if (!obj) return;

  for (const key in obj) {
    const result = fn(obj[key], key);
    if (result !== undefined) return result;
  }
};

export const tryParseJson = <T>(value): T => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

export const isEmptyObject = (object?: {}) => !Object.keys(object || {}).length;
