// export type DebouncedResult<TArgs extends any[]> = (...args: TArgs) => void & { clear: () => void};

const debounce = <T extends (...args: any[]) => void>(fn: T, wait: number) => {
  let timeout;

  const clear = () => clearTimeout(timeout);

  const debounced = (...args: Parameters<T>) => {
    clear();

    timeout = setTimeout(() => fn(...args), wait);
  };

  debounced.clear = clear;

  return debounced;
};

export default debounce;
