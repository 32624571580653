const KNOWN_STATIC_PROPS = [
  'getStaticProps',
  'getServerSideProps',
  'getInitialProps',
  'onReduxInitClient',
  'getInitialReduxStateServer',
  'i18nGetMessages',
  'i18nKeys',
];

export const hoistKnownProps = <F, T>(from: F, to: T) => {
  KNOWN_STATIC_PROPS.forEach((prop) => {
    if (from[prop]) to[prop] = from[prop];
  });

  return to;
};
