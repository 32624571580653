import React, { FC } from 'react';
import classnames from 'classnames';
import css from 'styled-jsx/css';

import useIsLargeScreen from '~/app/lib/hooks/useIsLargeScreen';

import Box from '../Box';
import Text from '../Text';
import Clickable from '../Clickable';
import CrossIcon from '../Icon/CrossIcon';

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface NotificationProps {
  testId?: string;
  type: NotificationType;
  text: string;
  onDismiss?(): void;
}

const GRADIENT_BY_TYPE: Record<NotificationType, string> = {
  [NotificationType.SUCCESS]: 'linear-gradient(135deg, #82c983, #509554)',
  [NotificationType.ERROR]: 'linear-gradient(135deg, #e46967, #ac3f39)',
};

const BORDER_RADIUS = '0.5rem';

const styles = css.resolve`
  .dismissButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const Notification: FC<NotificationProps> = ({
  testId,
  type,
  text,
  onDismiss,
}) => {
  const isLargeScreen = useIsLargeScreen();

  return (
    <Box data-testid={testId} flexRow style={{ borderRadius: BORDER_RADIUS }}>
      <Box
        width="0.8rem"
        style={{
          borderRadius: `${BORDER_RADIUS} 0 0 ${BORDER_RADIUS}`,
          backgroundImage: GRADIENT_BY_TYPE[type],
        }}
      />
      <Box
        flexRow
        flexGrow
        alignCenter
        padding="1.2rem 1.6rem"
        style={{
          gap: '1.6rem',
          backgroundColor: '#151515',
          borderRadius: `0 ${BORDER_RADIUS} ${BORDER_RADIUS} 0`,
          border: '0.1rem solid #444',
          borderLeft: 0,
        }}
      >
        <Text
          testId={testId}
          flexGrow
          color="#fff"
          size="1.6rem"
          lineHeight="2.4rem"
          lineClamp={2}
        >
          {text}
        </Text>
        {isLargeScreen && onDismiss && (
          <Clickable
            className={classnames(styles.className, 'dismissButton')}
            flexBox
            centerContent
            noFlexShrink
            isInline
            width="2.4rem"
            height="2.4rem"
            onClick={onDismiss}
            style={{ borderRadius: '50%', transition: 'background-color 0.2s' }}
          >
            <CrossIcon width="1.6rem" height="1.6rem" color="#888" />
          </Clickable>
        )}
      </Box>
      {styles.styles}
    </Box>
  );
};

export default Notification;
