import { isMobileBrowser } from '~/app/lib/device/utils';
import navigateWindow from './navigateWindow';

/**
 * Opens a new window creating a fresh browsing context.
 *
 * We used to open all external links in a new window, but this
 * is troublesome within mobile OS browsers that need to open
 * installed native apps for specific links. So now we only
 * open new window for desktop browsers.
 */
const openWindow = (url: string) => {
  // in non-mobile open a new tab to allow
  // users to get back to songwhip easily and keeps
  // tracking scripts running in parent window
  if (!isMobileBrowser(navigator.userAgent)) {
    // adding 'noopener' seemed to open in a new window instead
    // of a tab which seemed a bit clunky (on desktop)
    window.open(url, '_blank');

    return true;
  }

  // TODO: we should check how this behaves in standalone/installed web-apps
  // we want to use window.open() here to ensure that a third-party app
  // isn't running within our app chrome.

  // Navigate the current window away from our app. This is the
  // best UX on mobile as it means the OS can intercept the navigation
  // and open relevant installed native apps instead of the browser.
  // Plus tabs and long-running windows aren't as much a 'thing' on mobile.
  navigateWindow(url);

  return true;
};

export default openWindow;
