import { isInIframe } from '~/app/lib/device/utils';

const navigateWindow = (url: string) => {
  // if songwhip is in iframe always navigate the parent page
  // it's never fun navigating an iframe to a third-party
  if (isInIframe()) {
    window.parent.location.href = url;
  }

  // Navigate the current window away from our app. This is the
  // best UX on mobile as it means the OS can intercept the navigation
  // and open relevant installed native apps instead of the browser.
  // Plus tabs and long-running windows aren't as much a 'thing' on mobile.
  window.location.href = url;
};

export default navigateWindow;
