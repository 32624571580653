import { useContext, useEffect, useState } from 'react';

import {
  withI18n as withI18nHoc,
  useI18n as useI18nHook,
  initI18n,
  I18nMessageFormatters,
  I18nMessageFormatter,
  I18nContext,
  createMessageFormatters,
  Dictionary,
  I18nModule,
} from '@theorchard/suite-i18n-localization';

import config, { FRAGMENT_ROOT_KEY } from '~/i18n.config';
import { I18nTerms, I18nTermsByFragment } from '~/locales/types';
import { hoistKnownProps } from '../utils/hoistStaticProps';

const fetchMessages = async (
  key: string,
  locale: string
): Promise<I18nModule> => {
  const { default: fragment } = await import(
    /* webpackChunkName: "i18n-[request]" */ `locales/fragments/${key}/${locale}.json`
  );

  return fragment;
};

initI18n({
  fragmentRootKey: FRAGMENT_ROOT_KEY,
  onLoadMessages: (language: string, key: string) => {
    if (key === FRAGMENT_ROOT_KEY) {
      // This will reuse the i18n messages coming from the initial server render, not re-fetching them on the client.
      const messages =
        typeof window !== 'undefined' &&
        window.__NEXT_DATA__?.props?.i18nMessages;

      if (messages && messages[FRAGMENT_ROOT_KEY]) {
        return messages[FRAGMENT_ROOT_KEY];
      }
    }

    return fetchMessages(key, language);
  },
});

export type I18nFormatter<K extends keyof I18nTermsByFragment> =
  I18nMessageFormatter<I18nTermsByFragment[K]>;

export type I18nFormatters = I18nMessageFormatters<I18nTerms>;

/* eslint-disable no-redeclare */
export function useI18n<K extends keyof I18nTermsByFragment>(
  key: K
): I18nMessageFormatters<I18nTermsByFragment[K]>;
export function useI18n(): I18nMessageFormatters<I18nTerms>;

export function useI18n(key?: string) {
  if (key) return useI18nHook(key);
  return useI18nHook() as I18nMessageFormatters<I18nTerms>;
}
/* eslint-enable no-redeclare */

/**
 * Use given locales directly.
 */
export function useI18nStatic<K extends keyof I18nTermsByFragment>(locales: {
  [key: string]: Dictionary;
}) {
  const { locale } = useContext(I18nContext);
  if (!locale) throw new Error('Locale not set');

  const messages = locales[locale];

  return createMessageFormatters(messages) as I18nMessageFormatters<
    I18nTermsByFragment[K]
  >;
}

/**
 * Load given locale fragments dynamically.
 */
export function useI18nDynamic<K extends keyof I18nTermsByFragment>(key: K) {
  const { locale } = useContext(I18nContext);
  if (!locale) throw new Error('Locale not set');

  const [messages, setMessages] = useState<I18nModule | null>(null);

  useEffect(() => {
    if (messages) return;

    (async () => {
      const fragment = await fetchMessages(key, locale);

      setMessages(fragment);
    })();
  }, [key]);

  const formatters = createMessageFormatters(messages ?? {}, {
    emptyOnMissingTerm: !messages,
  }) as I18nMessageFormatters<I18nTermsByFragment[K]>;

  return {
    ...formatters,
    loading: messages === null,
  };
}

export function withI18n<
  K extends keyof I18nTermsByFragment,
  T extends React.ComponentType,
>(Page: T, keys: K[]) {
  const Wrapper = withI18nHoc(Page, keys);
  return hoistKnownProps(Page, Wrapper);
}

export const getEnabledLocales = () => config().locales;
