import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import onceIdle from '~/app/lib/utils/onceIdle';
import { raf } from '~/app/lib/utils/raf';

import HydrateScope from './HydrateScope';

const LazyHydrateDelay: FC<{
  delay: number;
  style?: CSSProperties;
  override?: boolean;
}> = ({ children, delay, style, override }) => {
  const [hydrate, setHydrate] = useState(false);
  const isUnmountedRef = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      onceIdle(() => {
        raf(() => {
          if (isUnmountedRef.current) return;
          setHydrate(true);
        });
      });
    }, delay);

    return () => {
      isUnmountedRef.current = true;
    };
  }, []);

  // In some cases we want to override the time and hydrate early.
  // An example of this is when the SideNav is opened before the timeout
  // is reached, we want to hydrate it asap so the buttons are usable.
  useEffect(() => {
    if (override) {
      setHydrate(true);
    }
  }, [override]);

  return (
    <HydrateScope
      render={useCallback(() => children, [children])}
      hydrate={hydrate}
      style={style}
    />
  );
};

export default LazyHydrateDelay;
