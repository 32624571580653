// forked from @essentials/raf

export type Raf = (callback: (timestamp: number) => void) => number;
export type Caf = (handle: number | undefined) => void;

const u = 'undefined';
const win = typeof window !== u ? window : {};

// @ts-ignore
// eslint-disable-next-line compat/compat
const p = typeof performance !== u && performance.now ? performance : Date;
const now = () => p.now();

const af = 'AnimationFrame';
const cafNs = 'cancel' + af;
const rafNs = 'request' + af;

let raf: Raf = win[rafNs] && win[rafNs].bind(win);
let caf: Caf = win[cafNs] && win[cafNs].bind(win);

if (!raf || !caf) {
  let lastTime = 0;

  raf = (callback: FrameRequestCallback) => {
    const curr = now();
    const next = Math.max(lastTime + 1000 / 60, curr);

    return setTimeout(() => {
      callback((lastTime = next));
    }, next - curr) as unknown as number;
  };

  caf = (h) => clearTimeout(h);
}

export { raf, caf, now };
