import { useSelector as _useSelector } from 'react-redux/es/hooks/useSelector';
import { useDispatch as _useDispatch } from 'react-redux/es/hooks/useDispatch';
import { useStore as _useStore } from 'react-redux/es/hooks/useStore';
import _Provider from 'react-redux/es/components/Provider';
import shallowEqual from 'react-redux/es/utils/shallowEqual';

// importing types
import type {
  useSelector as UseSelector,
  Provider as ProviderType,
} from 'react-redux';

import { AppReduxStore, Dispatch } from './types';

/**
 * react-redux doesn't seem to tree-shake unused modules by default.
 * This wrapper module carefully imports only the parts that this app
 * uses reducing bundled react-redux code by about 10kB.
 *
 * IMPORTANT: all modules that use react-redux must import from here
 * otherwise this optimization is undone.
 */

export const useSelector = _useSelector as typeof UseSelector;
export const useDispatch = _useDispatch as () => Dispatch;
export const useStore = _useStore as () => AppReduxStore;
export const Provider = _Provider as typeof ProviderType;
export { shallowEqual };
