export interface ApiErrorParams {
  type?: 'ApiError';
  message?: string;
  stack?: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  url?: string;
  showToast?: boolean;

  /**
   * If the request network failed then use status 0
   */
  status: 0 | number;
  code?: string;
  data?: any;
}

/**
 * A higher-level Error class that includes a `type`.
 *
 * I'm still not 100% happy with this class. What I really want is
 * to be able to define a `type` and have all props.
 *
 * Uses:
 *
 * - Non-200 API errors
 * - Fetch failure/offline
 *
 * Perhaps it's trying to be too many things? We could instead have several
 * different Error classes any use the AppError union type to reference them.
 */
class ApiError extends Error {
  status: number | undefined;
  code: string | undefined;
  showToast: boolean | undefined;
  method?: string;
  url?: string;
  data?: any;

  constructor(params: ApiErrorParams) {
    super(params.message || JSON.stringify(params));

    this.status = params.status;
    this.code = params.code;
    this.method = params.method;
    this.showToast = params.showToast;
    this.url = params.url;
    this.data = params.data;

    if (params.stack) this.stack = params.stack;
  }

  /**
   * TIL: This actually gets called by JSON.stringify()!
   *
   * Super handy if we want to customize how a class instance stored
   * in redux store is serialized before being sent to the client.
   */
  toJSON() {
    return {
      type: 'ApiError',
      message: this.message,
      stack: this.stack,
      method: this.method,
      url: this.url,
      status: this.status,
    };
  }
}

export default ApiError;
