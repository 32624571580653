import { ServerResponse } from 'http';

/**
 * A single interface to redirect both client/server
 */
export const httpRedirect = ({
  res,
  url,
  permanent = false,
}: {
  res: ServerResponse;
  url: string;

  /**
   * 301 or 302?
   *
   * WARNING: 301 can be cached by browser.
   */
  permanent?: boolean;
}) => {
  res.statusCode = permanent ? 301 : 302;
  res.setHeader('Location', url);
  res.end();
  res.finished = true;
};
