export const isMobileUserAgent = (userAgent: string) =>
  /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/.test(
    userAgent
  );

export const isTabletUserAgent = (userAgent: string) =>
  /(tablet|ipad|playbook|silk)|(android(?!.*mobile))/i.test(userAgent);

export const isMobileBrowser = (userAgent: string) =>
  isMobileUserAgent(userAgent) || isIos(userAgent);

export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const isGreatAtAnimations = (userAgent: string) => {
  // ios devices are usually great
  if (isIos(userAgent)) return true;

  if (isFirefox(userAgent)) return false;
  if (isIe(userAgent)) return false;

  if (isAndroid(userAgent)) {
    const cpus = navigator.hardwareConcurrency;
    if (!cpus) return false;

    // @ts-ignore
    const memory = navigator.deviceMemory;
    if (memory && memory < 2) return false;

    return true;
  }

  return true;
};

/**
 * Detect iOS.
 *
 * WARNING: this doesn't detect ipad pro which by default runs
 * Safari in 'desktop' mode with a desktop user-agent. The
 * only way to detect this is with clientside checks:
 * https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript
 */
export const isIos = (userAgent: string) =>
  /iphone|ipad|ipod/i.test(userAgent) || isIpadPro();

/**
 * Some touchable desktops don't have 'ontouchstart',
 * but they do have navigator.maxTouchPoints.
 */
export const isTouchDevice = () =>
  // eslint-disable-next-line compat/compat
  'ontouchstart' in document || !!navigator.maxTouchPoints;

const isIpadPro = () =>
  typeof navigator !== 'undefined' &&
  /MacIntel/.test(navigator.platform) &&
  isTouchDevice();

export const isAndroid = (userAgent: string) => /android/i.test(userAgent);

export const isFacebookWebView = (userAgent: string) =>
  /FBAN|FBAV/i.test(userAgent);

export const isInstagramWebView = (userAgent: string) =>
  /Instagram/i.test(userAgent);

export const isTikTokWebView = (userAgent: string) =>
  /musical_ly/i.test(userAgent);

export const isWebView = (userAgent: string | undefined) =>
  !!userAgent &&
  (isFacebookWebView(userAgent) ||
    isInstagramWebView(userAgent) ||
    isTikTokWebView(userAgent) ||
    // copied from apple musickit.js sdk
    /(webview|(iphone|ipod|ipad)(?!.*safari\/)|android.*(wv|\.0\.0\.0)|\bfb[\w_]+\/(?:messenger)?|\binstagram|\btwitter)/i.test(
      userAgent
    ));

/**
 * Test for Safari (desktop or mobile).
 *
 * Some ios (webview) browsers (eg chrome or firefox) don't don't include 'safari'
 * in the user agent, but do include 'iphone' or 'ipad', but they are running the
 * same safari engine.
 */
export const isSafari = (userAgent: string) =>
  /^((?!chrome|android).)*safari/i.test(userAgent) || isIos(userAgent);

export const isIe = (userAgent: string) =>
  /MSIE/.test(userAgent) ||
  // @ts-ignore
  !!(window.MSInputMethodContext && document.documentMode);

export const isFirefox = (userAgent: string) => /Firefox/.test(userAgent);
