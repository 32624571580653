import { useEffect, useState } from 'react';
import { useSelector } from '~/app/lib/store/redux';
import { getAppHasHydrated } from '~/app/components/NextApp';

import { DEVICE_WIDTH_BREAKPOINT } from '~/app/lib/device/constants';
import { selectIsSmallDevice } from '~/app/lib/store/session/selectors';
import debounce from '~/app/lib/utils/debounce';
import { on } from '../../utils/events';

const INITIAL_INNER_WIDTH_GLOBAL = '__initialInnerWidth';

/**
 * This script is injected inline in the <head> to read the window.innerWidth
 * before any layout has happened. Calling it inline on mount appeared to
 * trigger a forced layout.
 *
 * After initial value set on load, 'resize' listener is used to keep it up to date.
 */
export const USE_IS_LARGE_SCREEN_INLINE_SCRIPT = `window.${INITIAL_INNER_WIDTH_GLOBAL} = innerWidth;`;

let viewportWidth = process.browser ? window[INITIAL_INNER_WIDTH_GLOBAL] : 0;

const testIsLargeScreen = () => viewportWidth >= DEVICE_WIDTH_BREAKPOINT;

const useIsLargeScreen = () => {
  const isSmallDevice = useSelector(selectIsSmallDevice);

  // COMPLEX: if the app hasn't hydrated yet we must use the same value that
  // was used during SSR to ensure that if the value changes on mount (due to mismatch)
  // the hook will return a different value from first render and trigger a change
  const initialState = getAppHasHydrated()
    ? testIsLargeScreen()
    : !isSmallDevice;

  const [isLargeScreen, setIsLargeScreen] = useState(initialState);

  // update on viewport size change
  useEffect(() => {
    const onResizeDebounced = debounce(() => {
      viewportWidth = window.innerWidth;
      setIsLargeScreen(testIsLargeScreen());
    }, 200);

    // on mount we know the viewport size so we can get the ACTUAL value
    const actualIsLargeScreen = testIsLargeScreen();

    // COMPLEX: there is a chance that we guess the screen-size wrong and we
    // get the large-screen html instead of the small-screen (or vice-versa).
    // In this case we must explicitly trigger a state change. There will be
    // a flash as dom updates to the correct screen-size, but this is an edge-case.
    // On client we must initially return the same value as used on server and
    // then trigger a state change on mount. If the return value doesn't change
    // then react will consider the hook unchanged and won't re-render anything.
    // https://codesandbox.io/s/pensive-vaughan-bum2j?file=/src/index.js
    if (isLargeScreen !== actualIsLargeScreen) {
      setIsLargeScreen(actualIsLargeScreen);
    }

    const offResize = on(window, 'resize', onResizeDebounced);

    return () => {
      offResize();
      onResizeDebounced.clear();
    };
  }, []);

  return isLargeScreen;
};

export default useIsLargeScreen;
