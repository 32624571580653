export const on = <TObject extends HTMLElement | Window | Document>(
  object: TObject,
  eventName: string,
  callback: Parameters<TObject['addEventListener']>[1],
  options?: Parameters<TObject['addEventListener']>[2]
) => {
  object.addEventListener(eventName, callback, options);

  return () => object.removeEventListener(eventName, callback, options);
};
