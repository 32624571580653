import base from './base';
import { Theme } from './types';

const white = (alpha = 1) => `rgba(255,255,255,${alpha})`;
const black = (alpha = 1) => `rgba(0,0,0,${alpha})`;

const white30 = white(0.3);
const white10 = white(0.1);

const lightTheme: Theme = {
  ...base,
  background: white(),
  backgroundInverted: black(),

  textColor: 'hsl(0,0%,0%)',
  textColor90: 'hsl(0,0%,10%)',
  textColor80: 'hsl(0,0%,20%)',
  textColor70: 'hsl(0,0%,30%)',
  textColor60: 'hsl(0,0%,40%)',
  textColor50: 'hsl(0,0%,50%)',
  textColor40: 'hsl(0,0%,60%)',
  textColor30: 'hsl(0,0%,70%)',
  textColor20: 'hsl(0,0%,80%)',
  textColor10: 'hsl(0,0%,90%)',

  textColorInverted: white(),
  borderColor: black(0.1),

  textInputBackground: white10,
  textInputBorderColor: white30,

  toBackground: white,
  toTextColor: black,
};

export default lightTheme;
