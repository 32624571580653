/**
 * 'Songwhip Env' is our own environment flag that indicates
 * which services and databases in the stack are used.
 */
const getSongwhipEnv = () =>
  process.env.SONGWHIP_ENV as 'production' | 'staging';

export const getIsProduction = () => getSongwhipEnv() === 'production';

export default getSongwhipEnv;
