type CallbackParams = {
  didTimeout: boolean;
  timeRemaining: () => number;
};

type Task = (params: CallbackParams) => void | Promise<void>;

const requestIdleCallback = (() => {
  if (!process.browser) return;

  return (
    window.requestIdleCallback ||
    ((callback: (params: CallbackParams) => void | Promise<void>) => {
      const start = Date.now();

      return setTimeout(() => {
        callback({
          didTimeout: false,
          timeRemaining: () => Math.max(0, 50 - (Date.now() - start)),
        });
      }, 1);
    })
  );
})();

const cancelIdleCallback = (() => {
  if (!process.browser) return;

  return window.cancelIdleCallback || clearTimeout;
})();

const onceIdle = (task: Task) => {
  if (!process.browser) return;

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const id = requestIdleCallback!(task);

  return () => {
    cancelIdleCallback!(id);
  };
};

export default onceIdle;
