import { NonUndefined } from 'utility-types';

const removeUndefinedKeys = <T extends object>(object: T = {} as T) => {
  return Object.keys(object).reduce(
    (result, key) => {
      if (object[key] !== undefined) result[key] = object[key];
      return result;
    },
    {} as {
      [K in keyof T]: NonUndefined<T[K]>;
    }
  );
};

export default removeUndefinedKeys;
