import { NextRouter } from 'next/router';
import Debug from 'debug';

import { SCOPE_QUERY_PARAM } from '~/config';

import removeUndefinedKeys from '../utils/removeUndefinedKeys';
import { toPublicEndpoint } from '../getPublicEndpoint';
import { toRoute, ToRouteOptions } from './toRoute';

import {
  forceWindowLoad,
  formatUrl,
  isScopeableRoute,
  ParsedUrl,
  parseUrl,
  toQueryString,
} from './utils';

import {
  NEXT_DYNAMIC_ROUTE_PARAM_1,
  NEXT_DYNAMIC_ROUTE_PARAM_2,
} from './constants';

const initialHistoryState = process.browser
  ? (history.state as NextHistoryState)
  : undefined;

const debug = Debug('songwhip/AppRouter');

/**
 * Enable preserving the history index even after the
 * page is refreshed. This means that the in-app back
 * button will show and behave much like the browser
 * back button.
 */
const PRESERVE_INDEX_ON_RELOAD = false;

type RouteReplaceOptions = ToRouteOptions & { shallow?: boolean };
type RoutePushOptions = ToRouteOptions & { shallow?: boolean };

interface NextHistoryState {
  url: string;
  as: string;
}

export class AppRouter {
  private nextRouter: NextRouter;
  private accountIdScope: number | undefined;
  private pathScope: string | undefined;
  private scope: string | undefined;
  private historyIndex = 0;

  constructor({
    nextRouter,
    accountIdScope,
    pathScope,
    scope,
  }: {
    nextRouter: NextRouter;
    accountIdScope: number | undefined;
    pathScope: string | undefined;
    scope: string | undefined;
  }) {
    this.nextRouter = nextRouter;
    this.accountIdScope = accountIdScope;
    this.pathScope = pathScope;
    this.scope = scope;

    if (process.browser) {
      if (PRESERVE_INDEX_ON_RELOAD) {
        this.updateHistoryIndex(initialHistoryState!);
      } else {
        this.historyIndex = 0;
      }

      // use the nextjs event callback
      this.nextRouter.beforePopState(this.onBeforePopState);
    }
  }

  updateHistoryIndex(state: NextHistoryState) {
    const historyIndex = parseHistoryIndex(state.url);
    this.historyIndex = historyIndex || 0;
    debug('set historyIndex', this.historyIndex);
  }

  setNextRouter(nextRouter: NextRouter) {
    debug('set nextRouter');
    this.nextRouter = nextRouter;
  }

  toNextRouterParams(
    pagePath: string,
    { shallow, ...options }: RoutePushOptions = {}
  ) {
    const resolvedRoute = toRoute(pagePath, options);
    const { route, asUrl } = resolvedRoute;
    let { asPath } = resolvedRoute;

    const pageIsOutOfScope =
      (this.accountIdScope && !isScopeableRoute(route.pathname)) ||
      !isInPathScope(this.pathScope, asUrl.pathname);

    // for consistency force the page to reload to
    // blow away the scope session state, if the app
    // is operating behind a custom domain proxy (eg. foo.sng.to)
    // this will reload the page at the default origin (songwhip.com)
    if (pageIsOutOfScope) {
      forceWindowLoad(toPublicEndpoint(asPath));
      return;
    }

    if (this.pathScope) {
      const params = asUrl.pathname.split('/').filter(Boolean);

      // remove the scoped path param from the url
      const scopedPathname = applyPathScope(this.pathScope, asUrl.pathname);

      // manually define the dynamic route param so that NextPages
      // think that it's actually in the asPath:
      // https://github.com/vercel/next.js/pull/9837
      route.query[NEXT_DYNAMIC_ROUTE_PARAM_1] = params[0];
      route.query[NEXT_DYNAMIC_ROUTE_PARAM_2] = params[1];
      route.query[SCOPE_QUERY_PARAM] = this.scope!;

      asPath = formatUrl({
        ...asUrl,
        pathname: scopedPathname,
      });
    }

    return [route, asPath, { shallow }] as [
      ParsedUrl,
      string,
      { shallow: boolean | undefined },
    ];
  }

  /**
   * Called when history is navigated back/forward.
   *
   * The `state` passed here is the raw state from the browser.
   * Returning true here lets nextjs handle this and navigate to
   * the correct page. Nextjs will also 'clean' the `state` object
   * removing any third-party keys and calling `.replaceState()
   * to update the history stack.
   *
   * This means that the first time a history entry is popped the `state`
   * will contain any third-party keys but if popped again
   * (eg. from back -> forward -> back navigation) the `state`
   * object will be the cleaned one.
   */
  onBeforePopState = (state: NextHistoryState) => {
    debug('on pop state', state);
    this.updateHistoryIndex(state);
    return true;
  };

  async push(pagePath: string, options: RoutePushOptions = {}) {
    const routerParams = this.toNextRouterParams(pagePath, options);
    if (!routerParams) return;

    debug('push', routerParams);
    this.historyIndex++;

    const [route, asPath, routerOptions] = routerParams;
    route.query.historyIndex = String(this.historyIndex);

    await this.nextRouter.push(route, asPath, routerOptions);
  }

  async replace(pagePath: string, options: RouteReplaceOptions = {}) {
    const routerParams = this.toNextRouterParams(pagePath, options);
    if (!routerParams) return;

    debug('replace', routerParams);

    const [route, asPath, routerOptions] = routerParams;
    route.query.historyIndex = String(this.historyIndex);

    await this.nextRouter.replace(route, asPath, routerOptions);
  }

  back() {
    this.nextRouter.back();
  }

  canGoBack() {
    debug('can go back', this.historyIndex);
    return this.historyIndex > 0;
  }

  getPathname() {
    return this.nextRouter.pathname;
  }

  getQuery() {
    return this.nextRouter.query as Record<string, string>;
  }

  getAsPath() {
    return this.nextRouter.asPath;
  }

  /**
   * WARN: this isn't memoized
   */
  getAsQuery() {
    return parseUrl(this.nextRouter.asPath).query;
  }

  async setQuery(
    params: Record<string, string | undefined>,
    { type = 'push' }: { type?: 'push' | 'replace' } = {}
  ) {
    const queryString = toQueryString(
      removeUndefinedKeys({
        ...this.getAsQuery(),
        ...params,
      })
    );

    await this[type](`${this.getAsPathname()}?${queryString}${location.hash}`, {
      shallow: true,
    });
  }

  getAsPathname() {
    return parseUrl(this.nextRouter.asPath).pathname;
  }

  applyPathScope(pathname: string) {
    return applyPathScope(this.pathScope, pathname);
  }

  destroy() {
    if (process.browser) {
      // unbind callback
      // https://stackoverflow.com/questions/57231367/how-do-i-unbind-beforepopstate-binding-in-next-js-router
      this.nextRouter.beforePopState(() => true);
    }
  }
}

const parseHistoryIndex = (nextPathname: string) => {
  const { historyIndex } = parseUrl(nextPathname).query;
  return historyIndex && Number(historyIndex);
};

const toPathScopeRegex = (pathScope: string) =>
  new RegExp(`^/${pathScope}(?:/|$)`);

export const isInPathScope = (
  pathScope: string | undefined,
  pathname: string
) => {
  if (!pathScope) return true;
  return toPathScopeRegex(pathScope).test(pathname);
};

/**
 * Apply an active path scope to the given path:
 *
 * @example
 *
 *  // w/ scope=path:foo
 *  router.applyPathScope('/foo/bar/baz') => '/bar/baz'
 *
 *  // w/o scoping
 *  router.applyPathScope('/foo/bar/baz') => '/foo/bar/baz'
 */
const applyPathScope = (pathScope: string | undefined, pathname: string) =>
  pathScope ? pathname.replace(toPathScopeRegex(pathScope), '/') : pathname;
