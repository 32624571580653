import { ToColorParams } from './types';

const toAccentColor = ({ lightness = 48, alpha = 1 }: ToColorParams = {}) =>
  `hsla(335, 87%, ${lightness}%, ${alpha})`;

export default {
  fontFamily: `'Roboto', Helvetica Neue, Helvetica, sans-serif`,
  accentColor: toAccentColor(),
  toAccentColor,
  textColorOnAccent: '#fff',
  colorDanger: '#d95959',
  borderRadius: 5,
};
