import { createContext, FC, useContext, useEffect, useMemo } from 'react';
import { useRouter as useNextRouter } from 'next/router';

import { useSelector } from '~/app/lib/store/redux';

import {
  selectAccountIdSessionScope,
  selectPathSessionScope,
  selectSessionScope,
} from '../store/session/selectors';

import { AppRouter } from './AppRouter';

const AppRouterContext = createContext<{ router: AppRouter }>({} as any);

export const useAppRouter = () => useContext(AppRouterContext).router;

export const AppRouterProvider: FC = ({ children }) => {
  const accountIdScope = useSelector(selectAccountIdSessionScope);
  const pathScope = useSelector(selectPathSessionScope);
  const scope = useSelector(selectSessionScope);

  // this is a new object each time the route changes
  const nextRouter = useNextRouter();

  // router singleton
  const router = useMemo(
    () =>
      new AppRouter({
        nextRouter,
        accountIdScope,
        pathScope,
        scope,
      }),
    []
  );

  useEffect(() => {
    return () => {
      router.destroy();
    };
  }, []);

  if (process.browser) {
    // @ts-ignore - expose on window for dev/debugging
    window.__router = router;
  }

  // keep the underlying nextRouter reference up to date
  // so dependent components always get the latest state
  router.setNextRouter(nextRouter);

  // force the value object to change whenever the `asPath` changes,
  // this ensure dependent components render whenever the url changes
  const value = useMemo(
    () => ({
      router,
    }),
    [nextRouter.asPath]
  );

  return (
    <AppRouterContext.Provider value={value}>
      {children}
    </AppRouterContext.Provider>
  );
};
