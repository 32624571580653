import { toPixels } from './spacing';

const parseSpacingString = (value = ''): (string | number)[] => {
  if (!value) {
    return [0, 0, 0, 0];
  }

  const parts = value.split(' ');

  switch (parts.length) {
    case 1: {
      const px = toPixels(parts[0]);

      return [px, px, px, px];
    }

    case 2: {
      const vertical = toPixels(parts[0]);
      const horizontal = toPixels(parts[1]);

      return [vertical, horizontal, vertical, horizontal];
    }

    case 3: {
      const top = toPixels(parts[0]);
      const horizontal = toPixels(parts[1]);
      const bottom = toPixels(parts[2]);

      return [top, horizontal, bottom, horizontal];
    }

    case 4: {
      const top = toPixels(parts[0]);
      const right = toPixels(parts[1]);
      const bottom = toPixels(parts[2]);
      const left = toPixels(parts[3]);

      return [top, right, bottom, left];
    }

    default:
      throw new Error('unsupported format');
  }
};

export const toSpacingShorthand = (string) =>
  parseSpacingString(string)
    .map((value) => (typeof value === 'number' ? `${value}px` : value))
    .join(' ');
