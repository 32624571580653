import type { AnyAction, Reducer } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';

import type { ApiErrorParams } from '../errors/ApiError';
import type ApiError from '../errors/ApiError';

import type { DeleteItemSuccessActionType } from './lib/deleteItemSuccess';
import type { PathsAction, PathsActionTypes } from './paths/types';
import type { AccountAction } from './accounts/types';
import type { CreateActionTypes } from './create/types';

import type {
  AlbumAction,
  AlbumsActionTypes,
  SelectedAlbum,
  StoredAlbum,
} from './albums/types';

import type {
  ArtistAction,
  ArtistsActionTypes,
  SelectedArtist,
  StoredArtist,
} from './artists/types';

import type {
  SelectedTrack,
  TrackAction,
  TracksActionTypes,
  StoredTrack,
} from './tracks/types';

import type {
  CustomPageAction,
  CustomPagesActionTypes,
  SelectedCustomPage,
} from './customPages/types';

import type { ArtistsState } from './artists/reducer';
import type { AlbumsState } from './albums/reducer';
import type { TracksState } from './tracks/reducer';
import type { CustomPagesState } from './customPages/reducer';
import type { CreateState } from './create/reducer';
import type { DashboardState } from './dashboard/types';
import type { EditStageState } from './editStage/types';
import type { PathsState } from './paths/reducer';
import type { SessionState } from './session/types';

export type { SelectedCustomPage };

export interface State {
  editStage?: EditStageState;
  session: SessionState;
  artists: ArtistsState;
  albums: AlbumsState;
  tracks: TracksState;
  customPages: CustomPagesState;
  paths: PathsState;
  create: CreateState;
  dashboard: DashboardState;
}

export interface LazyReducers {
  editStage?: Reducer;
  create?: Reducer;
  prerelease?: Reducer;
  dashboard?: Reducer;
}

export enum StatusTypes {
  PENDING = 1,
  RESOLVED = 2,
  REJECTED = 3,
}

type ActionTypes =
  | ArtistsActionTypes
  | AlbumsActionTypes
  | TracksActionTypes
  | CustomPagesActionTypes
  | PathsActionTypes
  | CreateActionTypes
  | DeleteItemSuccessActionType['type'];

export interface ReduxAction {
  type: ActionTypes;
}

export type AppReduxAction =
  | ArtistAction
  | AlbumAction
  | TrackAction
  | CustomPageAction
  | PathsAction
  | DeleteItemSuccessActionType
  | AccountAction;

export type Dispatch = ThunkDispatch<State, any, AnyAction>;
export type StoredItem = StoredArtist | StoredAlbum | StoredTrack;

export type AppReduxStore = {
  getState: () => State;
  dispatch: Dispatch;
  lazyReducers: LazyReducers;
  replaceReducer: (reducer: Reducer) => void;
};

/**
 * Errors can be stored in several different ways. Errors are
 * serialized if they originated on the serverside. All
 * selectors accessing errors should use resolveError().
 */
export type StoredError =
  | ApiError
  | ApiErrorParams
  | Error
  | { message: string; stack?: string };

export type SelectedItem = SelectedArtist | SelectedAlbum | SelectedTrack;
