import { useEffect } from 'react';

import INTERCOM_INLINE_SCRIPT, { INTERCOM_APP_ID } from '~/app/lib/intercom';
import useFetchSessionUser from '~/app/lib/hooks/useFetchSessionUser';

const intercomIdentifier = 'intercom';

const useIntercom = () => {
  const { user } = useFetchSessionUser();

  useEffect(() => {
    const isIntercomScriptExists = Boolean(
      document.querySelector(`[data-${intercomIdentifier}]`)
    );

    // Load Intercom sdk only in case user is defined
    if (isIntercomScriptExists || !user) return;

    const script = document.createElement('script');

    script.dataset[intercomIdentifier] = '';
    script.dataset.testid = 'intercomScript';
    script.innerHTML = INTERCOM_INLINE_SCRIPT;

    document.head.appendChild(script);
  }, [user]);

  useEffect(() => {
    if (!user) return;

    window.Intercom?.('boot', {
      app_id: INTERCOM_APP_ID,
      user_id: user.id.toString(),
      user_hash: user.intercomIdentity,
      email: user.email,
      brand: user.orchardBrand,
    });

    return () => {
      window.Intercom?.('shutdown');
    };
  }, [user]);
};

export default useIntercom;
