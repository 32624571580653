import { LinksLocalized } from '../../utils/resolveLinksForCountry/lib/localizeLinks';
import { SelectedPrimaryOwnerAccount } from '../lib/selectors';
import { OrchardBrands } from '../../songwhipApi/types';
import ApiError from '../../errors/ApiError';
import { ReduxAction } from '../types';
import { ItemTypes } from '~/types';

import {
  MappedArtist,
  MappedPartialAlbum,
} from '../../songwhipApi/mapper/types';

export enum ArtistsActionTypes {
  FETCH_START = 'FETCH_ARTIST_START',
  FETCH_SUCCESS = 'FETCH_ARTIST_SUCCESS',
  FETCH_ERROR = 'FETCH_ARTIST_ERROR',
}

export interface FetchArtistStartAction extends ReduxAction {
  type: ArtistsActionTypes.FETCH_START;
  artistId: number;
}

export interface FetchArtistSuccessAction extends ReduxAction {
  type: ArtistsActionTypes.FETCH_SUCCESS;
  payload: MappedArtist;
  isPartial?: boolean;
}

export interface FetchArtistErrorAction extends ReduxAction {
  type: ArtistsActionTypes.FETCH_ERROR;
  artistId: number;
  error: Error;
}

export type StoredArtist = MappedArtist;

export interface SelectedArtist {
  type: ItemTypes.ARTIST;
  id: number;
  name: string;
  image: string | undefined;
  originalImage?: string;
  description: string | undefined;
  config: MappedArtist['config'];
  userCanEdit: boolean | undefined;
  error: ApiError | Error | undefined;
  isLoading: boolean;
  isOwned: boolean;
  isVariousArtists: boolean;
  pagePath: string;
  links: LinksLocalized | undefined;
  isStale: boolean;
  isShallow: boolean;
  ownedByAccountIds: number[] | undefined;
  pageBrand?: OrchardBrands;
  hasLinksForUserCountry: boolean;
  spotifyId: string | undefined;
  primaryOwnerAccount: SelectedPrimaryOwnerAccount | undefined;
  customLinks: MappedArtist['customLinks'];
  albums?: MappedPartialAlbum[];
  videos?: MappedArtist['videos'];
  shows?: MappedArtist['shows'];
}

export type ArtistAction =
  | FetchArtistStartAction
  | FetchArtistSuccessAction
  | FetchArtistErrorAction;
