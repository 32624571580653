/**
 * EXPERIMENTAL
 *
 * Add non-breaking-space whitespace character to prevent 1 or 2 'orphan'
 * words wrapping to the next line.
 *
 * Replacing the last 3 spaces with non-breaking-space means that all 3
 * words will wrap onto the next line which looks more pleasing to the eye.
 *
 * TODO: try to ensure the last line is the longest.
 */
const prettyWrap = (value: string | JSX.Element) => {
  if (typeof value !== 'string' || !value) {
    return value;
  }

  const words = value.split(' ');

  const totalWordsToJoin = Math.min(
    Math.max(Math.ceil(words.length / 2), 0),
    3
  );

  const lastWords = words.slice(-totalWordsToJoin).join(' ');
  if (!lastWords) return value;

  return `${words.slice(0, -totalWordsToJoin).join(' ')} ${lastWords}`;
};

export default prettyWrap;
