import { ItemTypes } from '~/types';

export type DeleteItemSuccessActionType = ReturnType<typeof deleteItemSuccess>;

export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS' as const;

export const deleteItemSuccess = (payload: {
  type: ItemTypes;
  id: number;
  path: string;
}) => ({
  type: DELETE_ITEM_SUCCESS,
  payload,
});
