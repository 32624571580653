import toNextPage from './toNextPage';
import { QueryParams, ParsedUrl, parseUrl, formatUrl } from './utils';

export type ToRouteOptions = {
  asQuery?: QueryParams;
  routerQuery?: QueryParams;
};

interface Route {
  route: ParsedUrl;
  asUrl: ParsedUrl;
  asPath: string;
}

export const toRoute = (
  path: string,
  { routerQuery, asQuery }: ToRouteOptions = {}
): Route => {
  const nextPage = toNextPage(path);
  if (!nextPage) throw new Error(`unrecognized path: ${path}`);

  const urlParsed = parseUrl(path);

  const asUrl = {
    ...urlParsed,
    query: {
      ...asQuery,
      ...urlParsed.query,
    },
  };

  return {
    route: {
      pathname: nextPage.path,

      // NOTE: these query params will not appear in the url
      query: {
        ...routerQuery,
        ...urlParsed.query,
      },
    },

    asUrl,
    asPath: formatUrl(asUrl),
  };
};
