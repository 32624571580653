import { Item } from '~/app/lib/songwhipApi/mapper';

import { ReduxAction } from '../types';

export type FetchPathStartActionType = 'FETCH_PATH_START';
export type FetchPathSuccessActionType = 'FETCH_PATH_SUCCESS';
export type FetchPathErrorActionType = 'FETCH_PATH_ERROR';

export const FETCH_PATH_START: FetchPathStartActionType = 'FETCH_PATH_START';
export const FETCH_PATH_ERROR: FetchPathErrorActionType = 'FETCH_PATH_ERROR';
export const FETCH_PATH_SUCCESS: FetchPathSuccessActionType =
  'FETCH_PATH_SUCCESS';

export interface FetchPathStartAction extends ReduxAction {
  type: FetchPathStartActionType;
  path: string;
}

export interface FetchPathSuccessAction extends ReduxAction {
  type: FetchPathSuccessActionType;
  path: string;
  payload: Item;
}

export interface FetchPathErrorAction extends ReduxAction {
  type: FetchPathErrorActionType;
  path: string;
  error: Error;
}

export type PathsActionTypes =
  | FetchPathStartActionType
  | FetchPathSuccessActionType
  | FetchPathErrorActionType;

export type PathsAction =
  | FetchPathStartAction
  | FetchPathSuccessAction
  | FetchPathErrorAction;
