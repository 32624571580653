import { useCallback, useEffect, useRef } from 'react';

const useIsUnmounted = () => {
  const isUnmountedRef = useRef(false);

  useEffect(() => {
    return () => {
      isUnmountedRef.current = true;
    };
  }, []);

  return useCallback(() => {
    return isUnmountedRef.current;
  }, []);
};

export default useIsUnmounted;
