const mergeItemIntoState = <TState extends Record<string | number, any>>(
  state: TState,
  key: string | number,
  props: TState[string | number]
) => {
  const existingItem = state[key];

  const nextItem = {
    ...existingItem,
    ...props,
  };

  return {
    ...state,
    [key]: nextItem,
  };
};

export default mergeItemIntoState;
