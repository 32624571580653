import { useCallback, useEffect, useRef, useState } from 'react';

import useFetchSessionUser from '~/app/lib/hooks/useFetchSessionUser';
import { OneTrustGroups } from '~/app/lib/oneTrust/types';
import { on } from '~/app/lib/utils/events';

/**
 * A hook that responds to the OneTrust privacy consent preferences,
 * exposing them to components that need to conditionally change
 * logic based on user preferences.
 */
const useOneTrust = () => {
  // HACK: to allow us to update the hook's returned getter function
  // when the user-prefs change. This allows clients to react to
  // privacy consent prefs and reattempt logic.
  const [state, forceUpdate] = useState({});
  const activeGroupsRef = useRef<string[]>([]);
  const { isLoggedIn } = useFetchSessionUser();

  useEffect(() => {
    const value: string | undefined = (window as any).OnetrustActiveGroups;

    const updateActiveGroups = (groups: string[] = []) => {
      activeGroupsRef.current = groups;
      forceUpdate({});
    };

    if (value) {
      const groups = value.split(',').filter(Boolean);
      updateActiveGroups(groups);
    }

    return on(
      window,
      'OneTrustGroupsUpdated',
      (event: CustomEvent<string[]>) => {
        updateActiveGroups(event.detail);
      }
    );
  }, []);

  // COMPLEX: instead of returning the values directly we return a getter function.
  // This means that clients don't need to keep track of the latest version of the
  // hook's return value and can instead call the getter at the point they need the
  // value and trust it is up to date. We changed to this approach after stale
  // values were being trapped inside closures preventing tracking events etc
  // being sent even after user-prefs had been updated.
  return useCallback(() => {
    const activeGroups = activeGroupsRef.current;

    return {
      pixelsEnabled:
        isLoggedIn || activeGroups.includes(OneTrustGroups.TARGETING),
      analyticsEnabled:
        isLoggedIn || activeGroups.includes(OneTrustGroups.PERFORMANCE),
    };
  }, [state]);
};

export default useOneTrust;
