/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

export type WithAppShellInjectedProps = {
  openAppDrawer: () => void;
};

// default values noop to be defined using <Provider> (see <NextApp>)
export default React.createContext<WithAppShellInjectedProps>({
  openAppDrawer: () => {},
});
