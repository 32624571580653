import nextRouter from 'next/router';

import { toPublicEndpoint } from '~/app/lib/getPublicEndpoint';
import { httpRedirect } from '~/app/lib/router2/httpRedirect';
import { toRoute } from '~/app/lib/router2/toRoute';

import {
  selectSessionCustomDomain,
  selectSessionUserId,
  selectUserBrand,
  selectUserType,
} from '~/app/lib/store/session/selectors';

import { AppPageContext, NextApp } from '../../types';

import resolveAppRedirect from './resolveAppRedirect';

export const withAppRedirects = (AppComponent: NextApp) => {
  const originalGetInitialProps = AppComponent.getInitialProps;

  AppComponent.getInitialProps = async (params: { ctx: AppPageContext }) => {
    const { reduxStore, asPath, pathname, res, req } = params.ctx;

    const path = asPath || pathname;
    const state = reduxStore.getState();
    const isCustomDomain = !!selectSessionCustomDomain(state);

    if (process.browser) {
      const userId = selectSessionUserId(state);
      const userType = selectUserType(state);
      const userBrand = selectUserBrand(state);

      const redirectPath = resolveAppRedirect({
        path,
        isCustomDomain,
        userBrand,
        userType,
        userId,
      });

      if (redirectPath) {
        const route = toRoute(redirectPath);

        nextRouter.replace(route.route, route.asPath);

        return {};
      }
      // COMPLEX: this SSR logic will only hit when the app ISN'T running
      // behind the edgeWorker proxy. This is because all user context is
      // removed for SSR when requested from edgeWorker. This server logic
      // is only in place to support redirects in development mode and if we
      // ever have to run the app without the edgeWorker for short periods.
    } else if (req && res) {
      const { resolveUserFromHeaders } = await import('../serverImports');

      // NOTE: As we do not store any user specific data in redux for SSR
      // we resolve user data from headers
      const userData = resolveUserFromHeaders(req.headers);

      const redirectPath = resolveAppRedirect({
        path,
        isCustomDomain,
        userBrand: userData?.userBrand,
        userType: userData?.userType,
        userId: userData?.userId,
      });

      if (redirectPath) {
        httpRedirect({
          res,
          url: toPublicEndpoint(redirectPath),
        });

        return {};
      }
    }

    return originalGetInitialProps!(params);
  };

  return AppComponent;
};
