import { ParsedUrlQuery } from 'querystring';
import {
  NEXT_DYNAMIC_ROUTE_PARAM_1,
  NEXT_DYNAMIC_ROUTE_PARAM_2,
} from './constants';

export type ParsedUrl = {
  pathname: string;
  query: QueryParams;
  hash?: string;
};

export type QueryParams = {
  [key: string]: string | undefined;
};

export const parseUrl = (path: string): ParsedUrl => {
  const [withoutHash, hash] = path.split('#');
  const [pathname, search] = withoutHash.split('?');
  const query = fromQueryString(search);

  return {
    pathname,
    query,
    hash,
  };
};

// REVIEW: can we use `new URL()` instead?
export const formatUrl = ({ pathname, query, hash }: ParsedUrl) => {
  let url = pathname;

  if (Object.keys(query).length) {
    const search = toQueryString(query);
    url += `?${search}`;
  }

  if (hash) {
    url += `#${hash}`;
  }

  return url;
};

export function fromQueryString(queryString: string): QueryParams {
  const decode = decodeURIComponent;
  const result = {};

  if (!queryString) return result;

  // remove leading '?' if present and split into pairs
  const pairs = queryString.replace(/^\?/, '').split('&');

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    result[decode(pair[0])] = decode(pair[1] || '');
  }

  return result;
}

export function toQueryString(
  object: Record<string, string | number | boolean | undefined>,
  { encode = true }: { encode?: boolean } = {}
) {
  const pairs: string[] = [];
  const encodeString = encode ? encodeURIComponent : (value) => value;

  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const keyEncoded = encodeString(key);

      // don't include undefined
      if (object[key] !== undefined) {
        const value = encodeString(object[key]);
        pairs.push(keyEncoded + (value ? `=${value}` : ''));
      }
    }
  }

  return pairs.join('&');
}

/**
 * Remove hash and search from a url path
 */
export const removePathSuffix = (pathWithQuery: string) => {
  return parseUrl(pathWithQuery).pathname;
};

export const isScopeableRoute = (routePathname: string) => {
  const SCOPEABLE_PAGE_PATTERN = /^\/\[param1\]/;
  return SCOPEABLE_PAGE_PATTERN.test(routePathname);
};

/**
 * Forces the app the hard load the given url
 * even if it matches the current window location.
 */
export const forceWindowLoad = (url: string) => {
  if (url === location.href) location.reload();
  else location.href = url;
};

export const toItemPagePath = (itemPath: string) => `/${encodeURI(itemPath)}`;

export const removeHash = (string: string) => string.replace(/#.*/, '');

export const removeQuery = (string: string) => string.replace(/\?.*/, '');

export const toDecodedItemPath = (query: ParsedUrlQuery) => {
  const param1 = tryDecodeUri((query as any)[NEXT_DYNAMIC_ROUTE_PARAM_1]);
  const param2 = tryDecodeUri((query as any)[NEXT_DYNAMIC_ROUTE_PARAM_2]);
  let result = '';

  if (param1) result += param1;
  if (param2) result += `/${param2}`;

  return result;
};

/**
 * A safer version of decodeURI that doesn't
 * error when encountering a lone `%`.
 */
const tryDecodeUri = (string: string) => {
  try {
    return string ? decodeURI(string) : string;
  } catch {
    return string;
  }
};
