import Debug from 'debug';

import { StatusTypes } from '../types';

import {
  SessionActionTypes,
  SessionInitClientAction,
  SessionLoginSuccessAction,
  SessionState,
} from './types';

const debug = Debug('songwhip/store/session/reducer');

// NOTE: initial values are set on serverside in components/NextApp
// @ts-ignore
const initialState: SessionState = {
  hydrated: false,
  user: {},
};

const reducer = (state: SessionState = initialState, action): SessionState => {
  // state.session is provided in the `initialState` passed to `createStore()`,
  // this trick ensures that the above `initialState` is still merged in
  // and the state is always in the expected (above) shape.
  // https://github.com/reduxjs/redux/issues/433#issuecomment-129188687
  if (!state.hydrated) {
    state = {
      ...initialState,
      ...state,
      hydrated: true,
    };
  }

  switch (action.type) {
    case SessionActionTypes.SESSION_INIT_CLIENT:
      return onInitClient(state, action);
    case SessionActionTypes.SET_USER_TYPE:
      return onSetUserType(state, action);

    // login
    case SessionActionTypes.SESSION_LOGIN_START:
      return onSessionLoginStart(state);
    case SessionActionTypes.SESSION_LOGIN_SUCCESS:
      return onSessionLoginSuccess(state, action);
    case SessionActionTypes.SESSION_LOGIN_ERROR:
      return onSessionLoginError(state, action);

    // logout
    case SessionActionTypes.SESSION_LOGOUT:
      return onSessionLogout(state);

    // fetch user
    case SessionActionTypes.FETCH_USER_START:
      return onFetchUserStart(state);
    case SessionActionTypes.FETCH_USER_SUCCESS:
      return onFetchUserSuccess(state, action);
    case SessionActionTypes.FETCH_USER_ERROR:
      return onFetchUserError(state, action);

    default:
      return state;
  }
};

const onInitClient = (
  state: SessionState,
  { type, ...params }: SessionInitClientAction
): SessionState => {
  return {
    ...state,
    ...params,
  };
};

const onSessionLoginStart = (state: SessionState): SessionState => {
  debug('on login start');

  return {
    ...state,

    user: {
      ...state.user,

      status: StatusTypes.PENDING,
      error: undefined,
    },
  };
};

const onSessionLoginSuccess = (
  state: SessionState,
  { user, userType, userBrand }: SessionLoginSuccessAction
): SessionState => {
  debug('on login success', user);

  return {
    ...state,

    userId: user.id,
    userType,
    userBrand,

    user: {
      status: StatusTypes.RESOLVED,
      error: undefined,
      value: user,
    },
  };
};

const onSessionLoginError = (state: SessionState, { error }): SessionState => {
  debug('on login error', error);

  return {
    ...state,

    userId: undefined,

    user: {
      status: StatusTypes.REJECTED,
      error,
    },
  };
};

const onSessionLogout = (state: SessionState): SessionState => {
  debug('on logout');

  return {
    ...state,

    userId: undefined,
    userType: undefined,
    userBrand: undefined,

    user: {
      status: undefined,
      value: undefined,
      error: undefined,
    },
  };
};

const onSetUserType = (state: SessionState, { value }): SessionState => {
  debug('on set user-type', value);

  return {
    ...state,
    userType: value,
  };
};

const onFetchUserStart = (state: SessionState): SessionState => {
  debug('on fetch user start');

  return {
    ...state,

    user: {
      ...state.user,
      status: StatusTypes.PENDING,
      error: undefined,
    },
  };
};

const onFetchUserSuccess = (state: SessionState, { user }): SessionState => {
  debug('on fetch user success');

  return {
    ...state,

    user: {
      status: StatusTypes.RESOLVED,
      error: undefined,
      value: user,
    },
  };
};

const onFetchUserError = (state: SessionState, { error }): SessionState => {
  debug('on fetch user error', error);

  return {
    ...state,

    user: {
      status: StatusTypes.REJECTED,
      error,
    },
  };
};

export default reducer;
