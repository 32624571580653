import Debug from 'debug';

import { AppReduxAction, StatusTypes, StoredError } from '../types';
import { MappedCustomPage } from '../../songwhipApi/mapper/types';
import { AccountsActionTypes } from '../accounts/types';

import {
  CustomPagesActionTypes as ActionTypes,
  StoredCustomPage,
} from './types';

const debug = Debug('songwhip/store/customPage/reducer');

type CustomPagesStateItem = {
  value: StoredCustomPage;
  status: StatusTypes;
  error?: StoredError;
};

export type CustomPagesState = {
  [artistId: string]: CustomPagesStateItem | undefined;
};

const initialState: CustomPagesState = {};

const reducer = (
  state: CustomPagesState = initialState,
  action: AppReduxAction
): CustomPagesState => {
  switch (action.type) {
    case ActionTypes.FETCH_CUSTOM_PAGE_START: {
      debug('on fetch custom page start: %s', action);

      return mergeCustomPageIntoState(state, action.customPageId, {
        status: StatusTypes.PENDING,
      });
    }

    case ActionTypes.FETCH_CUSTOM_PAGE_SUCCESS: {
      debug('on fetch custom page success: %s', action);
      const { payload } = action;

      return mergeCustomPageIntoState(state, payload.id, {
        status: StatusTypes.RESOLVED,
        value: toStoredCustomPage(payload),
        error: undefined,
      });
    }

    case ActionTypes.FETCH_CUSTOM_PAGE_ERROR: {
      debug('on fetch custom page error: %s', action);

      return mergeCustomPageIntoState(state, action.customPageId, {
        status: StatusTypes.REJECTED,
        error: action.error,
      });
    }

    // Purge all items when account config changes to ensure we never render
    // old `ownedByAccounts[].config`. This means that the item will be
    // re-fetched fresh from the server if/when CSR'd. This is a bit lazy
    // as we could selectively invalidate the items that have matching
    // ownedByAccounts[] but that would be more code to ship to every user :p
    case AccountsActionTypes.CONFIG_CHANGE: {
      return {};
    }

    default:
      return state;
  }
};

const mergeCustomPageIntoState = (
  state: CustomPagesState,
  customPageId: number,
  stateItem: Partial<CustomPagesStateItem>
) => {
  const existingItem = state[customPageId];

  const nextItem = {
    ...existingItem,
    ...stateItem,
  };

  return {
    ...state,
    [customPageId]: nextItem,
  };
};

const toStoredCustomPage = (payload: MappedCustomPage): StoredCustomPage => {
  const { artists, ...rest } = payload;

  return {
    ...rest,
    artistIds: artists?.map(({ id }) => id),
  };
};

export default reducer;
