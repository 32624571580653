import { AppReduxAction, StatusTypes, StoredError } from '../types';
import { DELETE_ITEM_SUCCESS } from '../lib/deleteItemSuccess';
import { CustomPagesActionTypes } from '../customPages/types';
import { ArtistsActionTypes } from '../artists/types';
import { AlbumsActionTypes } from '../albums/types';
import { TracksActionTypes } from '../tracks/types';
import { ItemTypes } from '~/types';

import {
  FETCH_PATH_START,
  FETCH_PATH_SUCCESS,
  FETCH_PATH_ERROR,
} from './types';

type PathsStateItem = {
  status: StatusTypes;
  error?: StoredError;

  value?: {
    type: ItemTypes;
    id: number;
  };
};

export type PathsState = {
  [path: string]: PathsStateItem | undefined;
};

const initialState: PathsState = {};

const reducer = (
  state: PathsState = initialState,
  action: AppReduxAction
): PathsState => {
  switch (action.type) {
    case FETCH_PATH_START: {
      const { path } = action;

      return {
        ...state,

        [path]: {
          status: StatusTypes.PENDING,
          value: undefined,
        },
      };
    }

    case FETCH_PATH_SUCCESS: {
      const { path, payload } = action;
      const pathChanged = path !== payload.path;

      // if the resolved path is different then we store a second
      // reference to the same item so that it can be looked up by either path
      // it's up to the view to .replace() the URL to match the item.path
      if (pathChanged) {
        return {
          ...state,

          [path]: {
            status: StatusTypes.RESOLVED,

            // store the id and type so that we can select items from their
            // dedicated store by path, it's effectively just a reference
            value: {
              type: payload.type,
              id: payload.id,
            },
          },
        };
      }

      return state;
    }

    case FETCH_PATH_ERROR: {
      const { path, error } = action;
      const existingItem = state[path];

      return {
        ...state,

        [path]: {
          ...existingItem,

          status: StatusTypes.REJECTED,
          error,
        },
      };
    }

    case ArtistsActionTypes.FETCH_SUCCESS:
    case AlbumsActionTypes.FETCH_ALBUM_SUCCESS:
    case TracksActionTypes.FETCH_TRACK_SUCCESS:

    case CustomPagesActionTypes.FETCH_CUSTOM_PAGE_SUCCESS: {
      const { payload } = action;

      return {
        ...state,

        [payload.path]: {
          status: StatusTypes.RESOLVED,

          // store the id and type so that we can select items from their
          // dedicated store by path, it's effectively just a reference
          value: {
            type: payload.type,
            id: payload.id,
          },
        },
      };
    }

    case DELETE_ITEM_SUCCESS: {
      const {
        payload: { path },
      } = action;

      const nextState = { ...state };
      delete nextState[path];

      return nextState;
    }

    default:
      return state;
  }
};

export default reducer;
