import { createContext, useMemo, FC, useContext, useEffect } from 'react';

import {
  SongwhipEvent,
  SongwhipEventContext,
} from '@theorchard/songwhip-events';

import useOneTrust from '~/app/components/OneTrust/useOneTrust';

import { useSelector, useStore } from '../store/redux';
import onceIdle from '../utils/onceIdle';

import {
  selectSessionUtmParams,
  selectUser,
  selectUserCountry,
  selectUserLanguage,
} from '../store/session/selectors';

import useFetchSessionUser, {
  pickFirstUserAccount,
} from '../hooks/useFetchSessionUser';

import { TrackClientEventParams } from './client';
import { trackClientEventLazy } from './';

export type TrackEvent = (
  event: SongwhipEvent,
  context?: Partial<SongwhipEventContext>,
  options?: TrackEventOptions
) => Promise<void>;

export interface TrackEventOptions {
  /**
   * Skip the check for the analytics opt-out.
   * This is useful when you want to track an event regardless of the user's preference.
   * e.g. when tracking the user's consents.
   */
  skipAnalyticsOptOutCheck?: boolean;
}

interface TrackingContextValue {
  trackEvent: TrackEvent;
  baseContext?: Partial<SongwhipEventContext> | null;
}

export const TrackerContext = createContext<TrackingContextValue>({
  trackEvent: async () => {
    // eslint-disable-next-line no-console
    console.error('no <TrackingProvider> in scope');
  },
  baseContext: {},
});

export interface TrackerProviderParams {
  baseContext?: Partial<SongwhipEventContext>;
}

let eventsBatch: TrackClientEventParams[] = [];
const MAX_BATCH_SIZE = 50;

export const TrackerProvider: FC<TrackerProviderParams> = ({
  baseContext,
  children,
}) => {
  const { baseContext: inheritedBaseContext } = useContext(TrackerContext);
  const { initialResolvedUser } = useFetchSessionUser();
  const utmParams = useSelector(selectSessionUtmParams);
  const { getState } = useStore();
  const oneTrust = useOneTrust();

  useEffect(() => {
    const isAnalyticsEnabled = oneTrust().analyticsEnabled;

    if (isAnalyticsEnabled && eventsBatch.length) {
      eventsBatch.slice(-MAX_BATCH_SIZE).forEach((params) => {
        onceIdle(() => trackClientEventLazy(params));
      });

      eventsBatch = [];
    }
  }, [oneTrust]);

  return (
    <TrackerContext.Provider
      value={useMemo(
        (): TrackingContextValue => ({
          baseContext,

          trackEvent: async (
            event: SongwhipEvent,
            context?: SongwhipEventContext,
            options?: TrackEventOptions
          ) => {
            // Get the current User from the store JUST before tracking the event
            // as the store may just have been updated before calling trackEvent().
            // Using a hook outside this closure can result in stale objects.
            const user = selectUser(getState());
            const language = selectUserLanguage(getState());
            const country = selectUserCountry(getState());
            const account = pickFirstUserAccount(user?.accounts);

            // Don't trigger any events until initial logged in User has been fetched otherwise
            // the first page-view event might be missing user params (race-condition).
            const initialUser = await initialResolvedUser;

            const params = {
              // Always use User from the redux store when defined as
              // this is the source-of-truth. But this may not be defined
              // if trackEvent() was called before the initial user request completes.
              user: user || initialUser,

              event,

              context: {
                language,

                accountId: account?.id,
                accountName: account?.name ?? undefined,

                userCountry: country,

                ...utmParams,

                ...inheritedBaseContext,
                ...baseContext,
                ...context,
              },
            };

            // skip sending event if user opt-out from analytics cookies
            if (
              !options?.skipAnalyticsOptOutCheck &&
              !oneTrust().analyticsEnabled
            ) {
              eventsBatch.push(params);
              return;
            }

            return trackClientEventLazy(params);
          },
        }),
        [baseContext]
      )}
    >
      {children}
    </TrackerContext.Provider>
  );
};

export const useTracker = () => {
  return useContext(TrackerContext);
};
