import { THEME_TYPE_DARK, THEME_TYPE_LIGHT } from './constants';

import dark from './dark';
import light from './light';

export * from './types';

export default {
  [THEME_TYPE_DARK]: dark,
  [THEME_TYPE_LIGHT]: light,
};
