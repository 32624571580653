import onceDocumentLoaded from './onceDocumentLoaded';
import onceIdle from './onceIdle';
import { useEffect, useRef, useState } from 'react';

const ARBITRARY_DELAY = 1500;
let appHydrated = false;

export const onceAppHydrated = (callback) => {
  if (appHydrated) {
    callback();
    return;
  }

  onceDocumentLoaded().then(() => {
    setTimeout(() => {
      onceIdle(() => {
        appHydrated = true;
        callback();
      });
    }, ARBITRARY_DELAY);
  });
};

export const useAppHydrated = () => {
  const unmountedRef = useRef(false);
  const [isHydrated, setIsHydrated] = useState(appIsHydrated());

  useEffect(() => {
    if (!appIsHydrated()) {
      onceAppHydrated(() => {
        if (unmountedRef.current) return;
        setIsHydrated(true);
      });
    }

    return () => {
      unmountedRef.current = true;
    };
  }, []);

  return isHydrated;
};

export const appIsHydrated = () => appHydrated;
