import { isSafari } from '../device/utils';

const DEFAULT_TTL_DAYS = 20 * 365; // 20 years

// TODO: with a little adaptation this could be used serverside

export const setClientCookie = (
  key: string,
  value: string | number = '',
  ttlDays: number = DEFAULT_TTL_DAYS
) => {
  document.cookie = toCookieValue({
    key,
    value,
    ttlDays,
  });
};

export const toCookieValue = ({
  key,
  value,
  ttlDays,
  userAgent = '',
  sameSiteNone,
}: {
  key: string;
  value: string | number;
  ttlDays?: number;
  userAgent?: string;
  sameSiteNone?: boolean;
}) => {
  let result = `${key}=${value}`;

  if (ttlDays) {
    const date = new Date();
    date.setTime(date.getTime() + ttlDays * 24 * 60 * 60 * 1000);
    result += `; expires=${date.toUTCString()}`;
  }

  if (sameSiteNone && !isSafari(userAgent)) {
    result += `; SameSite=None; Secure`;
  }

  return `${result}; path=/`;
};

export const toClearCookieValue = (key: string) =>
  `${key}=; max-age=-1; path=/`;

export const getClientCookie = <TValue = string>(name: string) =>
  parseCookie(document.cookie)[name] as TValue | undefined;

export const parseCookie = (cookie?: string) => {
  const result: Record<string, string | undefined> = {};

  if (!cookie) return result;
  const pairs = cookie.split(/; */);

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i];
    let eq_idx = pair.indexOf('=');

    // skip things that don't look like key=value
    if (eq_idx < 0) continue;

    const key = pair.substr(0, eq_idx).trim();
    const val = pair.substr(++eq_idx, pair.length).trim();

    // When there are multiple cookies the last one wins.
    // This helps workaround a strange bug where we've seen safari
    // hang on to old cookies and send old and new tokens
    // in the same response
    result[key] = tryDecode(val);
  }

  return result;
};

function tryDecode(str: string) {
  try {
    return decodeURIComponent(str);
  } catch (e) {
    return str;
  }
}

export const clearClientCookie = (name: string) => {
  document.cookie = toClearCookieValue(name);
};
